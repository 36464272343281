import { getAuctionAttendancesNumberByCarId } from '../../graphql/gqls/auctionAttendance/getAuctionAttendancesNumberByCarId'
import { setMyAuctionAttendance } from '../../graphql/gqls/auctionAttendance/setMyAuctionAttendance'
import { getNewAccessToken } from '../../graphql/gqls/auth/getNewAccessToken'
import { getMyBids } from '../../graphql/gqls/bid/getMyBids'
import { setBid } from '../../graphql/gqls/bid/setBid'
import { deleteCarById } from '../../graphql/gqls/car/deleteCarById'
import { getAllCars } from '../../graphql/gqls/car/getAllCars'
import { getAvailableCars } from '../../graphql/gqls/car/getAvailableCars'
import { getCarById } from '../../graphql/gqls/car/getCarById'
import { getCarDetailsById } from '../../graphql/gqls/car/getCarDetailsById'
import { getCarSuggestions } from '../../graphql/gqls/car/getCarSuggestions'
import { getMyCreatedCars } from '../../graphql/gqls/car/getMyCreatedCars'
import { setCar } from '../../graphql/gqls/car/setCar'
import { updateCar } from '../../graphql/gqls/car/updateCar'
import { updatePartialCar } from '../../graphql/gqls/car/updatePartialCar'
import { sendContactEmail } from '../../graphql/gqls/email/sendContactEmail'
import { getMyFavoriteCars } from '../../graphql/gqls/favorites/getMyFavoriteCars'
import { toggleMyFavorite } from '../../graphql/gqls/favorites/toggleMyFavorite'
import { getDeleteUrl } from '../../graphql/gqls/file/getDeleteUrl'
import { getUploadUrl } from '../../graphql/gqls/file/getUploadUrl'
import { getLabels } from '../../graphql/gqls/labels/labels'
import { getLicenses } from '../../graphql/gqls/license/getLicenses'
import { setLicense } from '../../graphql/gqls/license/setLicense'
import { updateLicense } from '../../graphql/gqls/license/updateLicense'
import { validateNewLicense } from '../../graphql/gqls/license/validateNewLicense'
import { getAllOrders } from '../../graphql/gqls/order/getAllOrders'
import { getMyOrders } from '../../graphql/gqls/order/getMyOrders'
import { placeOrder } from '../../graphql/gqls/order/palceOrder'
import { updateOrderState } from '../../graphql/gqls/order/updateOrderState'
import { getSession } from '../../graphql/gqls/session/getSession'
import { getPaymentIntent } from '../../graphql/gqls/stripe/getPaymentIntent'
import { createAdvancedUser } from '../../graphql/gqls/user/createAdvancedUser'
import { createUser } from '../../graphql/gqls/user/createUser'
import { deleteMyUser } from '../../graphql/gqls/user/deleteMyUser'
import { deleteUserById } from '../../graphql/gqls/user/deleteUserById'
import { getAllUsers } from '../../graphql/gqls/user/getAllUsers'
import { getMyAuthorizations } from '../../graphql/gqls/user/getMyAuthorizations'
import { getUserById } from '../../graphql/gqls/user/getUserById'
import { login } from '../../graphql/gqls/user/login'
import { resetPassword } from '../../graphql/gqls/user/resetPassword'
import { updateMyUserInfo } from '../../graphql/gqls/user/updateMyUserInfo'
import { updateUserAttributes } from '../../graphql/gqls/user/updateUserAttributes'
import { updateUserPassword } from '../../graphql/gqls/user/updateUserPassword'

const mapQueryNameFragments = {
  createUser,
  deleteCarById,
  deleteUserById,
  deleteMyUser,
  getAllCars,
  createAdvancedUser,
  getAllOrders,
  getAllUsers,
  getAvailableCars,
  getMyBids,
  setBid,
  getCarById,
  getCarDetailsById,
  getCarSuggestions,
  getMyCreatedCars,
  getDeleteUrl,
  getMyFavoriteCars,
  getLabels,
  validateNewLicense,
  getLicenses,
  getPaymentIntent,
  getUploadUrl,
  getUserById,
  login,
  placeOrder,
  resetPassword,
  sendContactEmail,
  setCar,
  setLicense,
  updateOrderState,
  toggleMyFavorite,
  updateCar,
  updatePartialCar,
  updateLicense,
  updateMyUserInfo,
  getMyOrders,
  updateUserPassword,
  getMyAuthorizations,
  updateUserAttributes,
  getNewAccessToken,
  getSession,
  setMyAuctionAttendance,
  getAuctionAttendancesNumberByCarId,
} as const

type QueryName = keyof typeof mapQueryNameFragments

export { mapQueryNameFragments }
export type { QueryName }
