import { useCallback } from 'react'
import { useNotificationContext } from '../providers/hooks'
import { useTranslation } from '../providers/hooks/i18n'
import { NotificationItem } from '../providers/types'

const useErrorManager = (id: NotificationItem['code']) => {
  const { setNotificationItems, notificationItems } = useNotificationContext()
  const { t } = useTranslation()

  const handleError = useCallback(
    (errorMessage: string) => {
      const message = t(`errorCodes.${errorMessage}`, errorMessage)

      if (!notificationItems.find(item => item.code === id)) {
        setNotificationItems([
          {
            message,
            code: id,
            severity: 'error',
          },
        ])
      }
    },
    [id, notificationItems, setNotificationItems, t]
  )

  return handleError
}

export const useGraphqlErrorManager = () => useErrorManager('graphqlErrors')
