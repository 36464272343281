import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import styled from 'styled-components'

export const CarouselDots = styled.li<{ isSelected: boolean }>`
  border: 1px solid white;
  background: ${({ isSelected }) => (isSelected ? 'white' : 'transparent')};
  width: 10px;
  height: 10px;
  border-radius: 100%;
`

export const CarouselImage = styled.img`
  border-radius: 8px;
  object-fit: contain;
  width: auto !important;
  height: 100%;
`

export const StyledCarousel = styled(Carousel)`
  border-radius: 8px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.black};

  .carousel-slider {
    height: 100%;
    max-height: 450px;
    border-radius: 8px;
  }

  .control-dots {
    display: flex;
    bottom: 0;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .slide {
    /* aspect-ratio: 1/1; */
  }
`
